import React from 'react';
import logo from '../assets/logo.svg';

const PrivacyPolicy = () => {
  return (
    <>
      <header style={{ textAlign: 'center', padding: 30 }}>
        <a href="/"><img src={logo} alt="road24" /></a>
      </header>
      <div className="privacy" style={{ padding: '20px 60px 40px' }}>
        <h1>Соглашение о разрешении на обработку персональных данных пользователей «Road24»</h1>
        <p>Настоящее соглашение по обработке персональных данных (далее – Политика конфиденциальности) действует в отношении всей информации, которую мобильное приложение «Road24» (далее – «Road24» или сайт <a href='https://road24.uz'>road24.uz</a>) может получить о Пользователе мобильного приложения «Road24» во время пользования мобильным приложением и его функциями.</p>
        <h2 className="sub-tile">1. Определение терминов</h2>
        <p>1.1. <b>«Персональные данные»</b> — зафиксированная на электронном, бумажном и (или) ином материальном носителе информация, относящаяся к определенному физическому лицу или дающая возможность его идентификации;</p>
        <p><b>«Субъект персональных данных (субъект)»</b> — физическое лицо, к которому относятся персональные данные;</p>
        <p><b>«База персональных данных»</b> — база данных в виде информационной системы, содержащая в своем составе персональные данные;</p>
        <p>1.2. <b>«Обработка персональных данных»</b> — реализация одного или совокупности действий по сбору, систематизации, хранению, изменению, дополнению, использованию, предоставлению, распространению, передаче, обезличиванию и уничтожению персональных данных;</p>
        <p>1.3. <b>«Конфиденциальность персональных данных»</b> - обязательное для соблюдения Оператором или иным получившим доступ к персональным данным лицом требование не допускать их распространения без согласия субъекта персональных данных или наличия иного законного основания.</p>
        <p>1.4. <b>«Пользователь Road24»</b> – лицо, имеющее доступ к сайту (<a href="https://road24.uz">road24.uz</a>), мобильному приложению посредством сети Интернет и использующее информацию, материалы и продукты Road24.</p>
        <p>1.5. <b>«Услуги по оплате штрафов»</b> - продукт, который Пользователь получает в мобильном приложении или на сайте и оплачивает через платежную систему, используемую в Road24.</p>
        <p>1.6. <b>«Оператор персональных данных»</b> или <b>«Оператор»</b> Общество с Ограниченной Ответственностью <b>«KASH APP»</b> как владелец мобильного приложения «Road24».</p>
        <p>1.7. Стороны удостоверяют, что не подлежат регистрации базы персональных данных, содержащие в своем составе персональные данные: сделанные Пользователем общедоступными.</p>
        <p>1.8. Пользователь после утверждения персональных данных дает свое согласие на обработку своих данных и делает их общедоступными.</p>
        <h2 className="sub-title">2. Общие положения</h2>
        <p>2.1. Использование Cайта ( <a href="https://road24.uz">road24.uz</a> ), приложения «Road24» Пользователем означает согласие с условиями настоящего соглашения и условиями обработки персональных данных Пользователя.</p>
        <p>2.2. В случае несогласия с условиями настоящего соглашения, Пользователь должен прекратить использование мобильного приложения «Road24» и Сайта (<a href='https://road24.uz'>Road24.uz</a>). Регистрация на мобильном приложении «Road24» Пользователя означает согласие субъекта персональных данных условиями настоящего соглашения.</p>
        <p>2.3. Настоящий документ устанавливает обязательства права и обязанности Оператора по передачи третьим лицам персональных данных, которые Пользователь предоставляет по запросу Оператора при регистрации на Сайте, при подписке на информационную e-mail рассылку или при оформлении заказа.</p>
        <p>2.4. Персональные данные, разрешённые к обработке в рамках настоящего Соглашения, предоставляются Пользователем путём заполнения форм в мобильном приложении ««Road24»» и включают в себя следующую информацию:</p>
        <p>2.4.1. Ф.И.О. и серия паспорта Пользователя;</p>
        <p>2.4.2. Контактный телефон Пользователя;</p>
        <p>2.4.3. Адрес электронной почты (e-mail) Пользователя - Опционально;</p>
        <p>2.4.4. Пол Пользователя - Опционально;</p>
        <p>2.4.5. Дата рождения Пользователя - Опционально;</p>
        <p>2.4.6 Сведения об Автомобиле Пользователя, а именно:</p>
        <ol type="1">
          <li>Государственный Номерной знак автомобиля с указанием (территориального кода, серии, Регистрационного номера и идентификатора страны);</li>
          <li>Серия Технического паспорта и его номер;</li>
          <li>Марка и модель автомобиля Пользователя.</li>
        </ol>
        <p>2.4.6. Иные данные об автомобиле Пользователя указанные в разделе «MyAuto» в мобильном приложении «Road24», а именно:</p>
        <ol>
          <li>Сведения о Страховке Автомобиля Пользователя - Опционально;</li>
          <li>Сведения о проведении Технического осмотра Автомобиля Пользователя и месте, где был проведён Тех. Осмотр - Опционально;</li>
          <li>Сведения о Доверенности на Автомобиль Пользователя с указанием лиц, которые имеют право пользованием данным транспортным средством и иными данными (период действия Доверенности, Типом Доверенности) - Опционально;</li>
          <li>Сведения о наличии Тонировки в Автомобиле Пользователя с дополнительными сведениями (период действия, Тип тонировки и иные сведения) – Опционально. Для предоставления данной опции, Оператор установит специальную связь с другими организациями, в том числе my.gov.uz.</li>
          <li>Сведения о замене Машинного масла Автомобиля Пользователя с дополнительными сведениями;</li>
        </ol>
        <p>2.4.7. Данные о штрафах на Автомобиль Пользователя с указанием следующих данных:</p>
        <ol type="1">
          <li>Количества полученных штрафов на Автомобиль Пользователя;</li>
          <li>Истории оплаченных и неоплаченных штрафов;</li>
          <li>Детализации штрафа с указанием след. сведений:
          <ol type="a">
              <li>Номера постановления штрафа;</li>
              <li>Изображения Автомобиля Пользователя в момент нарушения правил Дорожного движения с изображением Государственного Номерного знака автомобиля;</li>
              <li>Марки Автомобиля Пользователя;</li>
              <li>Описания нарушения на основании <b>Статьи из Кодекса Республики Узбекистан об Административной Ответственности</b>;</li>
              <li>Место нарушения правил дорожного движения с указанием точного адреса;</li>
              <li>Дата совершения нарушения правил дорожного движения;</li>
              <li>Номер Статьи из Кодекса Республики Узбекистан об Административной Ответственности;</li>
              <li>Сумма штрафа указанной в государственной валюте (Узбекский сум);</li>
              <li>Цифровой документ Штрафа в формате PDF с указанием всех сведений, описанных выше.</li>
              <li>Сведения о наличии штрафов.</li>
            </ol>
          </li>
        </ol>
        <p>2.4.8. Данные о геолокации Пользователя для использования следующих функций мобильного приложения «Road24»:</p>
        <ol type="1">
          <li>Отправки Репорта о нарушении правил Дорожного движения другими водителями с указанием Геолокации, Видео файла где это нарушение зафиксировано, дата и время данного события;</li>
        </ol>
        <p>2.4.9. Реквизиты банковской карты Пользователя для проведения финансовых операций по следующим функциям:</p>
        <ol type="1">
          <li>Оплата штрафа за нарушение правил дорожного движения, зафиксированных за Автомобилем Пользователя с возможностью частичного или полного погашения. Сведения об оплате штрафа будут доступны пользователю в разделе <b>«MyAuto > Штрафы > Не оплачено > История оплат»</b>, а также в разделе <b>«Уведомления»</b> в мобильном приложении Road24.</li>
          <li>Получение вознаграждения за фиксацию нарушения правил дорожного движения в разделе «Репорты» в мобильном приложении «Road24».</li>
        </ol>
        <p>2.5. Для предоставления возможности пользования Пользователем, Оператор имеет право устанавливать специальную связь с другими организациями, в том числе my.gov.uz.</p>
        <p>2.6. 2.6.В случае покупки опций (разрешительных документов) через данное приложение, Пользователь принимает и соглашается с ценой покупки, которая состоит из стоимости услуг Единого портала интерактивных государственных услуг и расходов Оператора.</p>
        <h2 className="sub-tile">3. Цели сбора персональной информации</h2>
        <p>3.1. Персональные данные Пользователя Оператор вправе использовать в целях:</p>
        <p>3.1.1. Идентификации Пользователя, зарегистрированного в мобильном приложении «Road24», для его дальнейшей идентификации, оформления репорта о нарушениях Правил Дорожного Движения другими водителями и других действий в рамках мобильного приложения «Road24».</p>
        <p>3.1.2. Предоставление Пользователю доступа к персонализированным данным мобильного приложения «Road24» и Сайта (<a href="https://road24.uz">road24.uz</a>).</p>
        <p>3.1.3. Установление с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования мобильного приложения «Road24» и Сайта (<a href="https://road24.uz">road24.uz</a>), оказания услуг и обработки репортов от Пользователя.</p>
        <p>3.1.4. Создание учетной записи для использования частей мобильного приложения «Road24» и Сайта (<a href="https://road24.uz">road24.uz</a>), если Пользователь дал согласие на создание учетной записи.</p>
        <p>3.1.5. Предоставление Пользователю специальных предложений, информации о товарах и услугах Оператора, партнеров Оператора, новостная рассылка.</p>
        <p>3.1.6. Предоставление Пользователю дополнительных услуг по страхованию и тонировки и др.</p>
        <p>3.2. После регистрации Пользователя на мобильном приложении «Road24» и на Сайте (<a href="https://road24.uz">road24.uz</a>), Оператор имеет право использовать персональные данные Пользователя для реализации действий, предусмотренных настоящим соглашением.</p>
        <p>3.3. Пользователь принимает и соглашается с тем, что он может покупать тонировку, страховой полис и другие разрешительные документы через данное приложение. В свою очередь, Оператор станет платформой между пользователем и Единым порталом интерактивных государственных услуг.</p>
        <h2 className="sub-tile">4. Способы и сроки обработки персональной информации</h2>
        <p>4.1. Обработка персональных данных Пользователя осуществляется без ограничения срока, любым законным способом, в том числе в информационных системах персональных данных с использованием средств автоматизации или без использования таких средств.</p>
        <p>4.2. Пользователь соглашается с тем, что Оператор вправе передавать персональные данные третьим лицам исключительно в целях обработки, предусмотренных настоящим соглашением.</p>
        <p>4.3. Оператор принимает необходимые организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.</p>
        <h2 className="sub-tile">5. Расторжение соглашения</h2>
        <p><b>Пользователь вправе отозвать свое согласие на обработку персональных данных, направив уведомление об этом Оператору в Telegram:  <a href="https://t.me/road24yordam">https://t.me/road24yordam</a>, за исключением случаев, когда персональные данные Пользователя необходимы Оператору для исполнения обязательств перед Пользователем.</b></p>
        <h2 className="sub-tile">6. Ответственность сторон</h2>
        <p>6.1. Оператор, виновно не исполнивший свои обязательства, несёт ответственность за реальный ущерб, понесенный Пользователем в связи с неправомерным использованием персональных данных, в соответствии с законодательством Республики Узбекистан. При это реальный ущерб Пользователя должен быть документально доказан.</p>
        <p>6.2. Оператор не несет ответственности перед Пользователем за любые убытки, произошедшие из-за: использования либо невозможности использования мобильного приложения «Road24» и Сайта (<a href="https://road24.uz">road24.uz</a>); несанкционированного доступа к коммуникациям Пользователя; поведения любого третьего лица на Сайте.</p>
        <p>6.3. Оператор не несет ответственность за какую-либо информацию, размещенную Пользователем в мобильном приложении «Road24» и Сайте (<a href="https://road24.uz">road24.uz</a>).</p>
        <h2 className="sub-tile">7. Дополнительные условия</h2>
        <p>7.1. Оператор вправе вносить изменения в настоящее соглашение и в Политику конфиденциальности без согласия Пользователя.</p>
        <p>7.2. Политика конфиденциальности вступает в силу с момента ее размещения в мобильном приложении «Road24» и на Сайте (<a href="https://road24.uz">road24.uz</a>).</p>
      </div>
    </>
  );
}

export default PrivacyPolicy;
